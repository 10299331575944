import React from "react";
import "./cropSolutionsMainBanner.css";
import Image from "../../images/cropSolutionsRoomBanner.png";

export default function CropSolutionsMainBanner() {
  return (
    <div>
      <div>
        <div style={{ position: "relative" }}>
          <img className="cropSolutions-Main-home-image" alt="" src={Image} />
          <div className="cropSolutions-Main-image-text-box">
            <div className="cropSolutions-Main-image-background-text">
              <h1 className="cropSolutions-Main-image-topic">Crop Solutions</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
