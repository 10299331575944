import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import "./aboutCropSolutions.css";

export default function CropSolutions() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div>
      <div className="cropSolutions-layout-42">
        <div className="cropSolutions-content2">
          <div
            className="cropSolutions-column2"
            data-aos="fade-right"
            data-aos-duration="700"
          >
            <div className="cropSolutions-heading">CIC Crop Solutions</div>
          </div>
          <div className="cropSolutions-column3">
            <div className="cropSolutions-text">
              <p
                className="cropSolutions-this-was-done"
                data-aos="fade-left"
                data-aos-duration="500"
              >
                The mainstay of CIC, the Crop Solutions cluster has been one of
                the key architects responsible for the growth and development of
                Sri Lanka’s agriculture sector in the past half a century. As
                the only entity in Sri Lanka to offer fully integrated
                solutions, CIC’s Crop Solutions cluster has succeeded in
                providing local farmers with the tools to benefit from modern
                agricultural practices.
              </p>
              <p className="cropSolutions-this-was-done">&nbsp;</p>
              <p
                className="cropSolutions-this-was-done"
                data-aos="fade-left"
                data-aos-duration="700"
              >
                Having tied up with a network of global principals the Crop
                Solutions cluster ensures that local farmers have access to the
                very latest and best quality seeds, plant nutrients and crop
                protection product. As part of our integrated approach, we
                combine our range of industry-leading products with necessary
                technical expertise for optimal application. To further augment
                the customer experience, we train our sales teams to offer
                guidance and continuous support to farmers and growers on yield
                maximisation and disease management methodologies.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
