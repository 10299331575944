import React from 'react'
import "./CICAgriBusinesses.css";
import InvestorWhyImage10 from "../../images/group-8.svg";

export default function CICAgriBusinesses() {
  return (
    <div className="agri-businesses-content">
      <center>
        <h2
          className="agri-bus-topic"
          data-aos="fade-up"
          data-aos-duration="500"
        >
          CIC Agri Businesses
        </h2>
      </center>
      <center>
        <div className="agri-businesses">
          <div
            className="green-back-para"
            data-aos="fade-left"
            data-aos-duration="500"
          >
            <img className="invest-image" alt="" src={InvestorWhyImage10} />
            <div>
              <p
                className="agri-businesses-para"
                data-aos="fade-right"
                data-aos-duration="700"
              >
                I have been involved in the cultivation project initiated by CIC
                Agri Businesses for 10 years. During this period, I have
                received constant support from the CIC team in terms of
                technical know-how and training. Most importantly they have
                provided me with the highest quality seeds for cultivation and
                offer a competitive price for my harvest, which has helped
                greatly in improving my living standards.
              </p>
            </div>
          </div>
        </div>
      </center>
    </div>
  );
}
