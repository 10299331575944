import React, { useEffect } from "react";
import "./CICPlantNutrition.css";
import InvestorWhyImage10 from "../../images/group-8.svg";
import WhiteLogo from "../../images/WhiteLogo.png";
import WhiteLogo2 from "../../images/WhiteLogo2.png";
import Aos from "aos";
import "aos/dist/aos.css";
import BrandCards1 from "./brand-cards-1";
import BrandCards2 from "./brand-cards-2";

export default function InvestorWhyInvest() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div>
      <div className="green-back-part">
        <center>
          <div
            className="green-back-para"
            data-aos="fade-left"
            data-aos-duration="500"
          >
            <img className="invest-image" alt="" src={InvestorWhyImage10} />
            <div>
              <div
                className="green-back-para-topic"
                data-aos="fade-right"
                data-aos-duration="700"
              >
                CIC’s Plant Nutrition portfolio
              </div>
              <p className="green-back-para-text">
                CIC’s Plant Nutrition portfolio offers top of the range, high
                quality plant nutrients to help boost the Country’s agricultural
                output. All Fertilizers and Fertlizer Products which are
                imported directly from our network of global principals, are
                repacked at our accredited fertilizer plants in Peliyagoda and
                Kurunegala. To further cement its dominance in Sri Lanka’s
                agri-input space, CIC diversified into the manufacture of
                organic fertiliser in 2021.
              </p>
            </div>
          </div>
        </center>
      </div>

      <div>
        <center>
          <div
            className="white-back-part"
            data-aos="fade-up"
            data-aos-duration="500"
          >
            <div className="white-back-part-text">
              <img className="white-back-part-logo" alt="" src={WhiteLogo} />
              <h2 data-aos="fade-right" data-aos-duration="500">
                Plant Nutrition
              </h2>
              <p data-aos="fade-left" data-aos-duration="500">
                Soil enrichment solutions to boost agricultural output. We
                represent the global brands
              </p>
              <BrandCards1 />
            </div>
            <div className="white-back-part-text-2">
              <img
                data-aos="fade-up"
                data-aos-duration="500"
                className="white-back-part-logo"
                alt=""
                src={WhiteLogo2}
              />
              <h2 data-aos="fade-right" data-aos-duration="500">
                Plant Protection
              </h2>
              <p
                className="white-back-part-text-2-p"
                data-aos="fade-left"
                data-aos-duration="500"
              >
                The Agro Chemical Re-packing center at Godagama produce an
                extensive range of Crop Protection compounds to safeguard crops
                against insects, pests and disease. Solutions for the protection
                of agricultural crops against insect and pest attacks.
              </p>
              <BrandCards2 />
            </div>
          </div>
        </center>
      </div>
    </div>
  );
}
