import React, { useEffect } from "react";
import "./brand-cards.css";

import Aos from "aos";
import "aos/dist/aos.css";

import Img1 from "../../images/Img01.png";
import Img2 from "../../images/Img02.png";
import Img3 from "../../images/Img03.png";
import Img4 from "../../images/Img04.png";
import Img5 from "../../images/Img5.png";

const brands = [
  {
    imgSrc: Img1,
  },
  {
    imgSrc: Img2,
  },
  {
    imgSrc: Img3,
  },
  {
    imgSrc: Img4,
  },
];

const BrandCards = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div className="brand-cards-wrapper">
      <div className="brand-cards-page-2">
        <center>
          <div className="brand-cards-container">
            {brands.map((brand, index) => (
              <img
                key={index}
                data-aos="zoom-in"
                src={brand.imgSrc}
                alt={`logo-${index}`}
                className="brand-logo"
              />
            ))}
          </div>
        </center>
      </div>
    </div>
  );
};

export default BrandCards;
