import React from "react";
import "./CICAgriBusinessesPart2.css";

import Image1 from "../../images/AgriBusinesses-1.png";
import Image2 from "../../images/AgriBusinesses-2.png";
import Image3 from "../../images/AgriBusinesses-3.png";

const ImageGrid = () => {
  return (
    <div>
      <center>
        <div className="part-2-image-grid-wrapper">
          <p className="next-part-description">
            CIC’s seed portfolio comprises a range of options for the
            cultivation of paddy, field crops, vegetables, and fruits and is
            made available across the Country through our distributor networks.
          </p>
          <div className="part-2-image-grid-container">
            <div
              className="part-2-image-item"
              data-aos="fade-right"
              data-aos-duration="500"
            >
              <img src={Image1} alt="Field Image 1" />
            </div>
            <div
              className="part-2-image-item"
              data-aos="fade-up"
              data-aos-duration="500"
            >
              <img src={Image2} alt="Field Image 2" />
            </div>
            <div
              className="part-2-image-item"
              data-aos="fade-left"
              data-aos-duration="500"
            >
              <img src={Image3} alt="Field Image 3" />
            </div>
          </div>
        </div>
      </center>
    </div>
  );
};

export default ImageGrid;
