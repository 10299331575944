import React from "react";
import ContactMainBanner from "../templates/CropSolutions/cropSolutionsMainBanner";
import AboutCropSolutions from "../templates/CropSolutions/aboutCropSolutions";
import CICPlantNutrition from "../templates/CropSolutions/CICPlantNutrition";
import CICAgriBusinesses from "../templates/CropSolutions/CICAgriBusinesses";
import CICAgriBusinesses2 from "../templates/CropSolutions/CICAgriBusinessesPart2";

export default function CropSolutions() {
  return (
    <div>
      {/* <Header /> */}
      <ContactMainBanner />
      <AboutCropSolutions />
      <CICPlantNutrition />
      <CICAgriBusinesses />
      <CICAgriBusinesses2 />
      {/* <Footer /> */}
    </div>
  );
}
